import { render, staticRenderFns } from "./OcrReportList.vue?vue&type=template&id=b6a4b3bc&scoped=true&"
import script from "./OcrReportList.vue?vue&type=script&lang=js&"
export * from "./OcrReportList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6a4b3bc",
  null
  
)

export default component.exports