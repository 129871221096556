<template>
  <ItemList
    :columns="columns"
    :items="items"
    :mode="mode"
    :report="true"
    @select="handleOpenFile"
  >
  </ItemList>
</template>

<script>
import ItemList from "@/components/common/display/ItemList.vue";

export default {
  name: "OcrReportList",

  components: { ItemList },

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    mode: {
      type: String,
      required: true,
    },
  },

  methods: {
    handleOpenFile(itemId, item) {
      this.$emit("view", itemId, item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
